export async function fetchHeaderText() {
    try {
        const response = await fetch('/app/api/FetchHeader');
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch headerText from API route: ", error);
        return { error: 'Failed to fetch data' };
    }
}
